<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-6">
        <h1>{{  `Montant total: ${cashRegisterReading.total_amount ?? 0}` }}</h1>
      </span>
      <div class="col-6 text-right">
      
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t('CASH_REGISTER_READINGS.STARTS_AT') }}</dt>
          <dd class="col-sm-8">
            {{ cashRegisterReading.starts_at }}
          </dd>
        </dl>
       
        <dl class="row">
          <dt class="col-sm-4">{{ $t('CASH_REGISTER_READINGS.ENDS_AT') }}</dt>
          <dd class="col-sm-8">
            {{ cashRegisterReading.ends_at }}
          </dd>
        </dl>
       
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="cashRegisterReading.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(cashRegisterReading.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(cashRegisterReading.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import IconCheck from "@/components/IconCheck.vue";

export default {
  name: "cash-register-reading-view-global",
  components: { IconCheck },

  props: ["cashRegisterReading"],

  data() {
    return {};
  },

  created() {},

  methods: {
    cashRegisterReadingUpdated() {
      this.$emit("cashRegisterReadingUpdated", true);
    },
  },

  mounted() {},

  watch: {
    cashRegisterReading(cashRegisterReading) {},
  },
};
</script>
