<template>
  <div>
    <div class="row mb-5">
      <span class="display-4 col-12">
        {{ `${cashRegisterReading.starts_at}` }} -
        {{ `${cashRegisterReading.ends_at}` }}
      </span>
    </div>

    <cash-register-reading-items
      v-if="cashRegisterReading.id"
      :filterOrganization="cashRegisterReading.organization?.id"
      :cashRegisterReading="cashRegisterReading.id"
      :key="renderKey"
      :addLineText="$t('SUPPLIERS.ADD_PRODUCT')"
    />
  </div>
</template>
<script>
import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue";
import CashRegisterReadingItems from "@/components/CashRegisterReadingItem/CashRegisterReadingItems.vue";

export default {
  name: "cash-register-reading-view-items",

  components: { LogListTable, CashRegisterReadingItems },

  props: {
    cashRegisterReading: {
      type: Object,
      default: null,
      description: "cash register reading",
    },
  },

  mixins: [],

  data() {
    return {
      renderKey: 0,
    };
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
