var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid mt-5"},[_c('div',[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.cashRegisterReading.total_amount)+" "),(
                  _vm.$currentUserCan(
                    _vm.$permissions.PERM_EDIT_CASH_REGISTER_READINGS
                  )
                )?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm"},on:{"click":function($event){return _vm.editCashRegisterReading()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-edit"})])]):_vm._e(),(
                  _vm.$currentUserCan(
                    _vm.$permissions.PERM_DELETE_CASH_REGISTER_READINGS
                  )
                )?_c('base-button',{attrs:{"type":"danger","icon":"","size":"sm"},on:{"click":function($event){return _vm.deleteCashRegisterReading()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()],1)]),_c('div',{staticClass:"col-2 text-right"},[_c('base-button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.goBack()}}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.RETURN_TO_LIST"))+" ")])],1)])]),_c('div',[_c('div',{staticClass:"col-12 justify-content-center justify-content-sm-between flex-wrap"},[_c('tabs',{staticClass:"flex-column flex-md-row",attrs:{"fill":"","tabNavWrapperClasses":"nav-wrapper","tabNavClasses":"nav nav-pills nav-fill","value":"global"}},[_c('card',{attrs:{"shadow":""}},[_c('tab-pane',{attrs:{"title":"global","id":"1","active":true}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"ni ni-cloud-upload-96"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.GLOBAL"))+" ")]),_c('cash-register-reading-view-global',{attrs:{"cashRegisterReading":_vm.cashRegisterReading},on:{"cashRegisterReadingUpdated":_vm.get}})],1),_c('tab-pane',{attrs:{"title":"global","id":"2"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"ni ni-cloud-upload-96"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.CASH_REGISTER_READING_ITEM"))+" Produits ")]),_c('cash-register-reading-view-items',{attrs:{"cashRegisterReading":_vm.cashRegisterReading},on:{"cashRegisterReadingUpdated":_vm.get}})],1),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOGS))?_c('tab-pane',{attrs:{"title":"logs","id":"999"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa fa-file"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.LOGS"))+" ")]),_c('cash-register-reading-view-logs',{attrs:{"cashRegisterReading":_vm.cashRegisterReading}})],1):_vm._e()],1)],1)],1)])],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }